<template>
  <div>

    <!-- banner start -->
    <section class="banner_sec home_banner">
      <div class="banner_hed">
        <h1 class="heading_2">Hi! We’re Performance Based Ergonomics.</h1>
        <p class="d-none">Text for ADA</p>
      </div>
    </section>

    <!-- heading start -->
    <section class="bg_grey heading_sec home_page_heading">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="heading_box">
              <h2 class="heading_3 ">
                We’re here to help you develop and maintain an exceptional and
                affordable ergo program.
              </h2>
            </div>
          </div>
          <div class="col-md-12">
            <div class="learn_more_btn text-center upMar">
              <button  v-scroll-to="{el: '#why-choose1',easing: 'linear',offset: -250,}" class="no-border">
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- heading end -->

    <!-- banner end -->

    <!-- client start -->
    <section class="client_sec py_100 testimonial py-xs-0" id="why-choose1">
      <div class="container" >
        <div class="row">
          <div class="col-md-12">
            <div class="client_head" >
              <h6 class="heading_6 text_secondary text-center">
                Some of Our Awesome Clients 
              </h6>

              <!-- <ul class="p-0">
                <li><img src="../../assets/home/images/home/c1.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c2.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c3.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c4.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c5.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c6.png" alt="img" /></li>
                <li><img src="../../assets/home/images/home/c7.png" alt="img" /></li>
              </ul>-->
              <carousel :margin="24" :nav="true" :loop="true" :autoplay="true"  :autoplayTimeout="1500" :navText="['&laquo;','&raquo;']" :dots="false" :responsive="{0:{items:1, nav:true},480:{items:2,  nav:true}, 768:{items:4,  nav:true},1280:{items:5, nav:true},1400:{items:6}}" class="owl-slider mt_50">     
             <!---<template slot="prev"><span class="prev"><img src='../../assets/home/images/learn-more/arrow-left.png' class='arrows'></span></template>---> 
                  <div class="item">
                      <img src="../../assets/home/images/home/Atlassian_Logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Chan_Zuckerberg_Logo.png" alt="img" />
                  </div>
                   <div class="item">
                      <h2>AMAZON</h2>
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Splunk-Corp-Logo-K-rgb.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Github_Logo.png" alt="img" />
                  </div>
                      <div class="item">
                      <h2>AIRBNB</h2>
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Instacart_logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Juniper_Logo.png" alt="img" />
                  </div>
                   <div class="item">
                      <h2>ETSY </h2>
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Netflix_Logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Nutanix_Logo_blue.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Pinterest_logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Pure_Storage_Logo.png" alt="img" />
                  </div>
                   <div class="item">
                      <h2>ROCHE</h2>
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Sutter_health_logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Twilio_Logo.png" alt="img" />
                  </div>
                  <div class="item">
                      <img src="../../assets/home/images/home/Twitch_logo.png" alt="img" />
                  </div>
                  
               
                  
                  
                   <!---<template slot="next"><span class="next"><img src='../../assets/home/images/learn-more/arrow-right.png'></span></template>--->
            </carousel>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- client end -->

    <!-- card start -->
    <section class="card_sec bg_grey py_100 ">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12">
            <div class="card_head mb_60">
              <h6 class="heading_6 m-0 text_secondary text-center">
                We Specialize In Ergo Program Management Solutions
              </h6>
            </div>
          </div>
          <div class="col-md-4 col-xl-3 col-sm-6">
            <div class="card_box">
              <router-link :to="{ name: 'self_assessments' }">
                <img src="../../assets/home/images/home/self.png" alt="img" />
                <h6>Self-Assessment & Training</h6>
               </router-link>
            </div>
          </div>

          <div class="col-md-4 col-xl-3 col-sm-6">
            <div class="card_box">
              <router-link :to="{ name: 'virtual_service' }">
                <img src="../../assets/home/images/home/ergonomic.png" alt="img" />
                <h6>Ergonomic <br> Services</h6>
               </router-link>
            </div>
          </div>

          <div class="col-md-4 col-xl-3 col-sm-6">
            <div class="card_box">
              <router-link :to="{ name: 'global_program' }">
                <img src="../../assets/home/images/home/global.png" alt="img" />
                <h6>Global Program Development</h6>
              </router-link>
            </div>
          </div>

          <div class="col-md-4 col-xl-3 col-sm-6">
            <div class="card_box free-res-outer">
              <router-link :to="{ name: 'return_to_office' }">
              <div class="new-free-res">New! <br />Free Resources</div>
                <img src="../../assets/home/images/home/retuern.png" alt="img" />
                <h6>Return to Office Hybrid Ergo</h6>
               </router-link>
            </div>
          </div>

          <div class="col-md-12">
            <div class="learn_more_btn remove_after text-center">
              <a href="#" v-scroll-to="{el: '#why-choose-us',easing: 'linear',offset: -100,}">
                <img src="../../assets/home/images/down_arrow.png" alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- card end -->

    <!-- image text start -->
    <section class="image_text_sec py_100" id="why-choose-us">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="why_choose_head text-center text_secondary mb_64">
              <h3 class="heading_4 font_bold m-0">Why Choose Us?</h3>
            </div>
          </div>
        </div>
        <div class="home_image_side">
          <div class="row justify-content-center">
            <div class="col-xxl-10">
              <div class="row mb_40 right_image_left_text">
                <div class="col-md-12 col-lg-6 col-xl-6">
                  <div class="image_text_right_side homehead">
                    <h6>We are your Ergo-Ally.</h6>
                      <p class="d-none">Text for ADA</p>
                    <h5 class="font_bold">Ergo has never been more important</h5>
                    <p class="mb_30">
                      Ergonomics has always been fundamental to the success of an
                      organization, but recent times has upended the way so many
                      employees are working. Whether your team is working on their
                      couch, dining room table, hoteling at the office or shuttling
                      between working onsite and at home, empowering them with
                      ergonomic training, wellness practices, stress management
                      techniques and productivity strategies is what will make all the
                      difference to them, to you and to your organization.
                    </p>
                    <p class="mb_34">
                      If you need help developing, expanding or managing an ergo
                      program, we’ve got you covered. Our cutting edge program
                      management and training platform will allow you to seamlessly
                      provide the help that your employees need, stay compliant and
                      adapt to our ever-changing work environments anywhere in the
                      world.
                    </p>
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-6">
                  <div class="image_left_side">
                    <img src="../../assets/home/images/home/we_are.png" alt="img" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 col-lg-6 col-xl-6">
                  <div class="image_left_side home_left_side">
                    <img src="../../assets/home/images/home/ergo.png" alt="img" />
                  </div>
                </div>
                <div class="col-md-12 col-lg-6 col-xl-6">
                  <div class="image_text_right_side home_right_side homehead">
                    <h6>Ergo Anywhere. Anytime. Any Device.</h6>
                      <p class="d-none">Text for ADA</p>
                    <h5 class="font_bold">Ergo at your fingertips. Literally.</h5>
                    <p class="mb_34">
                      Many of our clients are wrestling with the new challenges of
                      keeping employees safe and productive, no matter where they are
                      working. This is no easy feat.
                    </p>
                    <p class="mb_34">
                      Whether we partner to provide self-assessments, 1:1 evaluations,
                      back-end data & metrics and program management tools, or help
                      you design your dream program, the PBE team is here to help you
                      and your employees access vital information anytime, anywhere.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>



        <div class="row">
          <div class="col-md-12">
            <div class="text-center double-arrow mt_70">
              <a v-scroll-to="{el: '#program_section',easing: 'linear',offset: -100,}" ><img src="../../assets/home/images/down_arrow.png" alt="img" /></a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <!-- image text End -->

    <!-- program management start -->
    <section class="program_sec bg_grey py_100" id="program_section">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-xl-4">
            <div class="program_box program_fix">
              <img src="../../assets/home/images/home/p1.svg" width="50" height="50" alt="img" />
              <h6 class="heading_6">Program Management</h6>
              <p>
                No matter where you are in your ergo journey, we can help you
                from the very start, expand on what you already have and take
                your ergo program to the next level.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <div class="program_box">
              <img src="../../assets/home/images/home/p2.svg" width="40" height="50" alt="img" />
              <h6 class="heading_6">Healthy Workforce</h6>
              <p>
                All of our tools and services are designed to keep you and your
                employees healthy, happy and prepared to work at their very best
                wherever they find themselves working these days.
              </p>
            </div>
          </div>

          <div class="col-md-6 col-lg-6 col-xl-4">
            <div class="program_box">
              <img src="../../assets/home/images/home/p3.svg" width="50" height="50" alt="img" />
              <h6 class="heading_6">Dedicated Support</h6>
              <p>
                While our ergonomists and online tools support your employees,
                we support you! Our dedicated team of consultants, client
                success managers and tech team are here to support you every
                step of the way.
              </p>
            </div>
          </div>
          <div class="col-md-12">
            <div class="text-center mt_62 lets_chat_btn">
            <router-link class="btn btn_primary" :to="{ name: 'learn_more' }">
              Let’s Chat
            </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- program management end -->

    <!-- we proud start -->
    <section class="we_proud py_100">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="we_proud_head text-center">
              <h6 class="heading_6 text_secondary mb_50">
                We’re Proud To Be Featured In
              </h6>
              <img src="../../assets/home/images/home/we_logo.png" alt="img" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- we proud end -->
  </div>
</template>

<script>
  // import { mapGetters } from "vuex";
  // import store from "@/store/index";
  import "vue-select/dist/vue-select.css";
  import carousel from "vue-owl-carousel";

  export default {
    name: "home",
    mounted() {
      document.title = "PBErgo | Ergonomics Training | Consulting | Consultant";
      // store.dispatch("Home/getHomeData").then(() => {});
    },
    components: { carousel },
    data() {
      return {
        navText: ["Prev", "Next"],
        responsive: { 0: { items: 1, nav: true }, 600: { items: 3, nav: true },  768: { items: 3, nav: true } }
      }
    }
  };
</script>